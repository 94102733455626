import * as React from "react";
import { Component } from 'react';
import { createTheme, Customizations } from "@fluentui/react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import withAuth from "./hoc/Auth";
import "./App.css";

import Status from "./components/pages/Status";
import Layout from "./components/standard/Layout";
import { AppContextProvider } from "./config/AppContext";

const myTheme = createTheme({
  palette: {
    themePrimary: "#00305e",
    themeLighterAlt: "#cfdeec",
    themeLighter: "#a8c2da",
    themeLight: "#84a7c8",
    themeTertiary: "#648fb7",
    themeSecondary: "#4978a5",
    themeDarkAlt: "#316493",
    themeDark: "#1d5182",
    themeDarker: "#0c4070",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

const KeyProvider: React.FC = ({ children }) => {
  const [key, setKey] = React.useState("");
  React.useEffect(() => {}, []);

  return (
    <AppContextProvider value={{ key, setKey }}>{children}</AppContextProvider>
  );
};

export class RootApp extends Component<any, any> {
  public render() {
    let result = false;
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(localStorage.getItem("useAadAuthentication") ?? "false");
    }
    console.log(result);
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(
        localStorage.getItem("useAadAuthentication") ?? "false"
      );
    }
    if (this.props.auth === null && !this.props.auth?.isAuthenticated && result)
      return <></>;
    Customizations.applySettings({ theme: myTheme });
    let contents = this.renderContent();
    return <div>{contents}</div>;
  }

  private renderContent() {
    return (
      <KeyProvider>
        <Layout>
          <Switch>
            <Route exact path="/" component={Status} />
          </Switch>
        </Layout>
      </KeyProvider>
    );
  }
}
export const App = withAuth(RootApp);