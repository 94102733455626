import * as React from 'react';
import { msalConfig, msalScope } from '../msal/msalConfig';
import { AccountInfo, AuthenticationResult, EndSessionRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';

export interface IState {
    isAuthenticated: boolean;
    Account: AccountInfo | null;
    errorMessage: string;
    hasError: boolean;
};

export const M365Auth = new PublicClientApplication({
    auth: msalConfig,
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
});


export default function withAuth(HocComponent : any) {
    return class extends React.Component<any, IState> {
        private msalApplication: PublicClientApplication;
        private account?: AccountInfo;

        constructor(props: any) {
            super(props);
            this.msalApplication = M365Auth;
        }

        async componentWillMount() {
            await this.HandlePageLoadEvent();
        }

        HandlePageLoadEvent(): Promise<void> {
            let result = false;
            if (localStorage !== undefined && localStorage.hasOwnProperty("useAadAuthentication")) {
                result = JSON.parse(localStorage.getItem("useAadAuthentication") ?? "false");
            }

            if (!result)
                return new Promise<void>((resolve) => resolve(undefined));

            // let exceptions bubble up to the caller to handle
            return this.msalApplication.handleRedirectPromise().then((authResult: AuthenticationResult | null) => {
                this.HandleRedirectResponse(authResult);
            });
        }

        HandleRedirectResponse(authResult: AuthenticationResult | null): void {
            // if this page load is redirect from the Microsoft Identity platform then the
            // authResult will be populated. Otherwise null on other page loads.

            if (authResult !== null) {
                this.setState({ isAuthenticated: true, Account : authResult.account});
            }
            else {
                // see if we have cached accounts.
                const currentAccounts = this.msalApplication.getAllAccounts();
                if (currentAccounts.length <= 0) {
                    this.SignIn();
                    return;
                }
                else if (currentAccounts.length > 1) {
                    // there are some situations where the user may have multiple (different) cached logins.
                    // this code sample does not cover that scenario but just logs a warning here.
                    // this conditional block would need to be updated to support multiple accounts.
                    this.setState({ isAuthenticated: true, Account: currentAccounts[0] });
                }
                else if (currentAccounts.length === 1) {
                    this.setState({ isAuthenticated: true, Account: currentAccounts[0] });
                }
            }
        }
        SignIn() {

            let loginRedirectRequestPayload: RedirectRequest = {
                scopes: msalScope.scopes,
                prompt: "select_account"
            }

            // this will redirect the web application to the Microsoft Identity platform sign in pages.
            // no code will execute after this point.
            this.msalApplication.loginRedirect(loginRedirectRequestPayload);
        }

        SignOut() {
            if (!this.account) {
                // no cached login to signout
                return;
            }

            let accountInfo: AccountInfo | null = this.msalApplication.getAccountByUsername(this.account?.username as string);

            if (accountInfo !== null) {
                let logoutRequestPayload: EndSessionRequest = {
                    account: accountInfo
                }

                this.msalApplication.logout(logoutRequestPayload)
            }
        }

        public render(): JSX.Element {
            let result = false;
            if (localStorage !== undefined && localStorage.hasOwnProperty("useAadAuthentication")) {
                result = JSON.parse(localStorage.getItem("useAadAuthentication") ?? "false");
            }

            if (!result)
                return <HocComponent {...this.props} />;
            return <HocComponent auth={this.state} {...this.props} />;
        }
    }
}