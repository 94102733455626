import * as React from "react";
import {
  MessageBar,
  MessageBarType
} from "@fluentui/react";

export default class Status extends React.Component<any, any> {
  public render(): JSX.Element {
    console.log(localStorage.getItem("isConfigured"));
    let content = (
      <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false}>
        appsettings.json konnte nicht gefunden werden. Bitte richten sie im M353 UniversalLink ein DMS ein!
      </MessageBar>
    );
    if (localStorage.getItem("isConfigured") === 'true') {
      content = <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
        Der M365 UniversalLink SharePoint Adapter wurde erfolgreich eingerichtet! Sie können ihn nun verwenden.
      </MessageBar>;
    }
    return <div>{content}</div>;
  }
}
