import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import './index.css';
import { App } from './App';
import StatusModel from './components/Models/StatusModel'
import reportWebVitals from './reportWebVitals';

initializeIcons();
initializeFileTypeIcons();

fetch('api/Status/IsConfigured', {
    method: 'GET',
    redirect: 'follow'
}).then(response => response.json())
    .then((data: StatusModel) => {
        localStorage.setItem("isConfigured", (data.isConfigured.toString()));
        localStorage.setItem("authority", data.authority);
        localStorage.setItem("clientId", data.clientId);
        localStorage.setItem("scope", data.scope);
        localStorage.setItem("useAadAuthentication", JSON.stringify(data.useAad));
        ReactDOM.render(<React.StrictMode><App /></React.StrictMode>,document.getElementById('root'));
    })
    .catch((error) => {
        console.log(error);
    })


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
