import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

import '../../css/navigation.css';

export default class Layout extends React.Component<any, any> {
    public render() {
        return (
            <div>
                <NavMenu loadPersona={true} />
                <div className="clearfix" >
                    <div className="right">
                        <Container style={{ margin: "0px", padding:"0px", maxWidth:"100%"}}>
                            {this.props.children}
                        </Container>
                    </div >
                </div>
            </div>
        );
    }
}